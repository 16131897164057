import { default as aboutccpC8lqMSLMeta } from "/Users/i/dev/iAuto/ivv/pages/about.vue?macro=true";
import { default as activitymmHp1AXnVeMeta } from "/Users/i/dev/iAuto/ivv/pages/community_event/activity.vue?macro=true";
import { default as _91id_93eKVyNzavtVMeta } from "/Users/i/dev/iAuto/ivv/pages/community_event/form_activity/[id].vue?macro=true";
import { default as _91id_93HFN5gFhVu9Meta } from "/Users/i/dev/iAuto/ivv/pages/community_event/form_reservation/[id].vue?macro=true";
import { default as _91id_939xhyUb5nYcMeta } from "/Users/i/dev/iAuto/ivv/pages/community_event/form_volunteer/[id].vue?macro=true";
import { default as reservationR45JFsCbh9Meta } from "/Users/i/dev/iAuto/ivv/pages/community_event/reservation.vue?macro=true";
import { default as _91id_93DQ52ZupqmRMeta } from "/Users/i/dev/iAuto/ivv/pages/community_event/view_activity/[id].vue?macro=true";
import { default as _91id_93BEGfut81dYMeta } from "/Users/i/dev/iAuto/ivv/pages/community_event/view_reservation/[id].vue?macro=true";
import { default as _91id_93CAcXkcayX1Meta } from "/Users/i/dev/iAuto/ivv/pages/community_event/view_volunteer/[id].vue?macro=true";
import { default as volunteerms0ljeyZLjMeta } from "/Users/i/dev/iAuto/ivv/pages/community_event/volunteer.vue?macro=true";
import { default as _91community_home_id_93jAcV0o4uUqMeta } from "/Users/i/dev/iAuto/ivv/pages/community_home/community_event/[community_home_id].vue?macro=true";
import { default as indexc2ONoB0WsNMeta } from "/Users/i/dev/iAuto/ivv/pages/community_home/index.vue?macro=true";
import { default as _91community_home_id_93mqkz1rLu7UMeta } from "/Users/i/dev/iAuto/ivv/pages/community_home/swiper/[community_home_id].vue?macro=true";
import { default as indexLEu3wkNgB4Meta } from "/Users/i/dev/iAuto/ivv/pages/index.vue?macro=true";
import { default as loginkoWzef9PjOMeta } from "/Users/i/dev/iAuto/ivv/pages/login.vue?macro=true";
import { default as indexZifmynuU71Meta } from "/Users/i/dev/iAuto/ivv/pages/order/index.vue?macro=true";
import { default as listyHXZ1q65bnMeta } from "/Users/i/dev/iAuto/ivv/pages/order/list.vue?macro=true";
import { default as _91id_937puowumZJNMeta } from "/Users/i/dev/iAuto/ivv/pages/route/form/[id].vue?macro=true";
import { default as indexl9H8uW9DobMeta } from "/Users/i/dev/iAuto/ivv/pages/route/index.vue?macro=true";
import { default as _91appid_93XKNZ5zkg0FMeta } from "/Users/i/dev/iAuto/ivv/pages/wxamp_user/[appid].vue?macro=true";
import { default as index0qFIHwBAFFMeta } from "/Users/i/dev/iAuto/ivv/pages/wxamp_user/group/index.vue?macro=true";
import { default as _91appid_93nBqwJox6UdMeta } from "/Users/i/dev/iAuto/ivv/pages/wxamp/setting/[appid].vue?macro=true";
export default [
  {
    name: aboutccpC8lqMSLMeta?.name ?? "about",
    path: aboutccpC8lqMSLMeta?.path ?? "/about",
    meta: { ...(aboutccpC8lqMSLMeta || {}), ...{"middleware":["auth"]} },
    alias: aboutccpC8lqMSLMeta?.alias || [],
    redirect: aboutccpC8lqMSLMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/about.vue").then(m => m.default || m)
  },
  {
    name: activitymmHp1AXnVeMeta?.name ?? "community_event-activity",
    path: activitymmHp1AXnVeMeta?.path ?? "/community_event/activity",
    meta: { ...(activitymmHp1AXnVeMeta || {}), ...{"middleware":["auth"]} },
    alias: activitymmHp1AXnVeMeta?.alias || [],
    redirect: activitymmHp1AXnVeMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/activity.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eKVyNzavtVMeta?.name ?? "community_event-form_activity-id",
    path: _91id_93eKVyNzavtVMeta?.path ?? "/community_event/form_activity/:id()",
    meta: { ...(_91id_93eKVyNzavtVMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93eKVyNzavtVMeta?.alias || [],
    redirect: _91id_93eKVyNzavtVMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/form_activity/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93HFN5gFhVu9Meta?.name ?? "community_event-form_reservation-id",
    path: _91id_93HFN5gFhVu9Meta?.path ?? "/community_event/form_reservation/:id()",
    meta: { ...(_91id_93HFN5gFhVu9Meta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93HFN5gFhVu9Meta?.alias || [],
    redirect: _91id_93HFN5gFhVu9Meta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/form_reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939xhyUb5nYcMeta?.name ?? "community_event-form_volunteer-id",
    path: _91id_939xhyUb5nYcMeta?.path ?? "/community_event/form_volunteer/:id()",
    meta: { ...(_91id_939xhyUb5nYcMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_939xhyUb5nYcMeta?.alias || [],
    redirect: _91id_939xhyUb5nYcMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/form_volunteer/[id].vue").then(m => m.default || m)
  },
  {
    name: reservationR45JFsCbh9Meta?.name ?? "community_event-reservation",
    path: reservationR45JFsCbh9Meta?.path ?? "/community_event/reservation",
    meta: { ...(reservationR45JFsCbh9Meta || {}), ...{"middleware":["auth"]} },
    alias: reservationR45JFsCbh9Meta?.alias || [],
    redirect: reservationR45JFsCbh9Meta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/reservation.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DQ52ZupqmRMeta?.name ?? "community_event-view_activity-id",
    path: _91id_93DQ52ZupqmRMeta?.path ?? "/community_event/view_activity/:id()",
    meta: { ...(_91id_93DQ52ZupqmRMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93DQ52ZupqmRMeta?.alias || [],
    redirect: _91id_93DQ52ZupqmRMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/view_activity/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BEGfut81dYMeta?.name ?? "community_event-view_reservation-id",
    path: _91id_93BEGfut81dYMeta?.path ?? "/community_event/view_reservation/:id()",
    meta: { ...(_91id_93BEGfut81dYMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93BEGfut81dYMeta?.alias || [],
    redirect: _91id_93BEGfut81dYMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/view_reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93CAcXkcayX1Meta?.name ?? "community_event-view_volunteer-id",
    path: _91id_93CAcXkcayX1Meta?.path ?? "/community_event/view_volunteer/:id()",
    meta: { ...(_91id_93CAcXkcayX1Meta || {}), ...{"middleware":["auth"]} },
    alias: _91id_93CAcXkcayX1Meta?.alias || [],
    redirect: _91id_93CAcXkcayX1Meta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/view_volunteer/[id].vue").then(m => m.default || m)
  },
  {
    name: volunteerms0ljeyZLjMeta?.name ?? "community_event-volunteer",
    path: volunteerms0ljeyZLjMeta?.path ?? "/community_event/volunteer",
    meta: { ...(volunteerms0ljeyZLjMeta || {}), ...{"middleware":["auth"]} },
    alias: volunteerms0ljeyZLjMeta?.alias || [],
    redirect: volunteerms0ljeyZLjMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_event/volunteer.vue").then(m => m.default || m)
  },
  {
    name: _91community_home_id_93jAcV0o4uUqMeta?.name ?? "community_home-community_event-community_home_id",
    path: _91community_home_id_93jAcV0o4uUqMeta?.path ?? "/community_home/community_event/:community_home_id()",
    meta: { ...(_91community_home_id_93jAcV0o4uUqMeta || {}), ...{"middleware":["auth"]} },
    alias: _91community_home_id_93jAcV0o4uUqMeta?.alias || [],
    redirect: _91community_home_id_93jAcV0o4uUqMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_home/community_event/[community_home_id].vue").then(m => m.default || m)
  },
  {
    name: indexc2ONoB0WsNMeta?.name ?? "community_home",
    path: indexc2ONoB0WsNMeta?.path ?? "/community_home",
    meta: { ...(indexc2ONoB0WsNMeta || {}), ...{"middleware":["auth"]} },
    alias: indexc2ONoB0WsNMeta?.alias || [],
    redirect: indexc2ONoB0WsNMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_home/index.vue").then(m => m.default || m)
  },
  {
    name: _91community_home_id_93mqkz1rLu7UMeta?.name ?? "community_home-swiper-community_home_id",
    path: _91community_home_id_93mqkz1rLu7UMeta?.path ?? "/community_home/swiper/:community_home_id()",
    meta: { ...(_91community_home_id_93mqkz1rLu7UMeta || {}), ...{"middleware":["auth"]} },
    alias: _91community_home_id_93mqkz1rLu7UMeta?.alias || [],
    redirect: _91community_home_id_93mqkz1rLu7UMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/community_home/swiper/[community_home_id].vue").then(m => m.default || m)
  },
  {
    name: indexLEu3wkNgB4Meta?.name ?? "index",
    path: indexLEu3wkNgB4Meta?.path ?? "/",
    meta: { ...(indexLEu3wkNgB4Meta || {}), ...{"middleware":["auth"]} },
    alias: indexLEu3wkNgB4Meta?.alias || [],
    redirect: indexLEu3wkNgB4Meta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginkoWzef9PjOMeta?.name ?? "login",
    path: loginkoWzef9PjOMeta?.path ?? "/login",
    meta: { ...(loginkoWzef9PjOMeta || {}), ...{"middleware":["auth"]} },
    alias: loginkoWzef9PjOMeta?.alias || [],
    redirect: loginkoWzef9PjOMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexZifmynuU71Meta?.name ?? "order",
    path: indexZifmynuU71Meta?.path ?? "/order",
    meta: { ...(indexZifmynuU71Meta || {}), ...{"middleware":["auth"]} },
    alias: indexZifmynuU71Meta?.alias || [],
    redirect: indexZifmynuU71Meta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: listyHXZ1q65bnMeta?.name ?? "order-list",
    path: listyHXZ1q65bnMeta?.path ?? "/order/list",
    meta: { ...(listyHXZ1q65bnMeta || {}), ...{"middleware":["auth"]} },
    alias: listyHXZ1q65bnMeta?.alias || [],
    redirect: listyHXZ1q65bnMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/order/list.vue").then(m => m.default || m)
  },
  {
    name: _91id_937puowumZJNMeta?.name ?? "route-form-id",
    path: _91id_937puowumZJNMeta?.path ?? "/route/form/:id()",
    meta: { ...(_91id_937puowumZJNMeta || {}), ...{"middleware":["auth"]} },
    alias: _91id_937puowumZJNMeta?.alias || [],
    redirect: _91id_937puowumZJNMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/route/form/[id].vue").then(m => m.default || m)
  },
  {
    name: indexl9H8uW9DobMeta?.name ?? "route",
    path: indexl9H8uW9DobMeta?.path ?? "/route",
    meta: { ...(indexl9H8uW9DobMeta || {}), ...{"middleware":["auth"]} },
    alias: indexl9H8uW9DobMeta?.alias || [],
    redirect: indexl9H8uW9DobMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/route/index.vue").then(m => m.default || m)
  },
  {
    name: _91appid_93XKNZ5zkg0FMeta?.name ?? "wxamp_user-appid",
    path: _91appid_93XKNZ5zkg0FMeta?.path ?? "/wxamp_user/:appid()",
    meta: { ...(_91appid_93XKNZ5zkg0FMeta || {}), ...{"middleware":["auth"]} },
    alias: _91appid_93XKNZ5zkg0FMeta?.alias || [],
    redirect: _91appid_93XKNZ5zkg0FMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/wxamp_user/[appid].vue").then(m => m.default || m)
  },
  {
    name: index0qFIHwBAFFMeta?.name ?? "wxamp_user-group",
    path: index0qFIHwBAFFMeta?.path ?? "/wxamp_user/group",
    meta: { ...(index0qFIHwBAFFMeta || {}), ...{"middleware":["auth"]} },
    alias: index0qFIHwBAFFMeta?.alias || [],
    redirect: index0qFIHwBAFFMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/wxamp_user/group/index.vue").then(m => m.default || m)
  },
  {
    name: _91appid_93nBqwJox6UdMeta?.name ?? "wxamp-setting-appid",
    path: _91appid_93nBqwJox6UdMeta?.path ?? "/wxamp/setting/:appid()",
    meta: { ...(_91appid_93nBqwJox6UdMeta || {}), ...{"middleware":["auth"]} },
    alias: _91appid_93nBqwJox6UdMeta?.alias || [],
    redirect: _91appid_93nBqwJox6UdMeta?.redirect,
    component: () => import("/Users/i/dev/iAuto/ivv/pages/wxamp/setting/[appid].vue").then(m => m.default || m)
  }
]
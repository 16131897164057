import type { User } from "~/api/model.type"

interface progress {
  loading: boolean,
  reverse: boolean,
  color: string,
}

export const useWebsiteStore = defineStore('websiteStore', {
  state: () => ({
    token: '',
    panel_drawer: false,
    dark: false,
    syncDark: true, // 是否开启主题同步
    title: '',
    user: {} as User,
    progress: {
      loading: false,
      reverse: false,
      color: 'cyan',
    } as progress,
  }),
  actions: {
    logout() {
      this.setToken('')
      this.setUser({})
      const router = useRouter()
      router.push('/login')
    },
    setSyncDark(value: boolean){
      this.syncDark = value
    },
    setUser(value: any) {
      this.user = value
    },
    setToken(value: string) {
      this.token = value
    },
    setTitle(value: string) {
      this.title = value
      console.log('title', this.title)
    },
    // 是否客户端
    clientFunc(fn = () => {}) {
      if (process.client) {
        fn()
      } 
    },
    setDark(value: boolean) {
      this.dark = value
      console.log('dark', this.dark)
    },
    setPanelDrawer(value: boolean) {
      this.panel_drawer = value
      console.log('panel_drawer', this.panel_drawer)
    },
    setProgress(value: progress) {
      this.progress = value
      console.log('loading', this.progress)
    }
  },
  persist: process.client && {
    storage: localStorage,
    paths: ['dark', 'token', 'user', 'syncDark']
  }
})

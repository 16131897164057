<template>
  <NuxtLayout>
    <v-app>
      <api-error/>
      <NuxtPage />
    </v-app>
  </NuxtLayout>
</template>

<script setup lang="ts">

import { useTheme } from 'vuetify';


const website = useWebsiteStore()
const theme = useTheme()

function detectTheme() {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    console.log('Dark theme is enabled');
    website.setDark(true)
  } else {
    console.log('Light theme is enabled');
    website.setDark(false)
  }
}

onMounted(() => {
  theme.global.name.value = website.dark ? 'dark' : 'light'
  if (process.client){
    document.documentElement.classList.toggle('dark', website.dark)
  }
  if (process.client) {
    // detectTheme();
    // 监听系统主题变化
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function(e) {
      if(website.syncDark){
        detectTheme();
      }
    });
  }
})

// 监听 website.syncDart 的变化
watch(() => website.syncDark, (newVal) => {
  console.log(newVal,website.syncDark )
  if(newVal){
    detectTheme();
  }
})
// 监听 website.dark 变化
watch(() => website.dark, (newVal) => {
  theme.global.name.value = newVal ? 'dark' : 'light'
  if( process.client ){
    // 切换 tailwindcss 模式
    document.documentElement.classList.toggle('dark', newVal)
  }
})
// 监听 website.title 变化
watch(() => website.title, (newVal) => {
  if( process.client ){
    document.title = newVal
  }
})



</script>

<style lang="scss">
  html,body {
    overflow: auto!important;
    background-color: rgb(var(--v-theme-background))!important;
  }
  /* 修改垂直滚动条 */
  ::-webkit-scrollbar {
    width: 5px; /* 修改宽度 */
    height: 5px; /* 修改高度 */
  }

  /* 修改滚动条轨道背景色 */
  ::-webkit-scrollbar-track {
    background-color: #e2e8f0;
  }

  /* 修改滚动条滑块颜色 */
  ::-webkit-scrollbar-thumb {
    background-color: #475569
  }

  /* 修改滚动条滑块悬停时的颜色 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #94a3b8;

  }

  /* 修改滚动条滑块移动时的颜色 */
  ::-webkit-scrollbar-thumb:active {
    background-color: #94a3b8;

  }

  /* 修改滚动条滑块的圆角 */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  // 修改v-select的样式
  .v-select__content{
    border-radius: 1.5rem!important;
    overflow: hidden!important;
  }

  .rounded-xl{
    border-radius: 1.5rem!important;
  }
  .rounded-xl-bk{
    border-radius: 1.5rem!important;
    overflow: hidden!important;
  }
  .v-field--variant-solo{
    border-radius: 1.5rem!important;
    overflow: hidden!important;
  }
  .v-picker-title{
    padding-bottom: 0!important;
  }
  
</style>